module.exports = {
  name: 'English',
  'Link': 'Link',
  'Open in editor': 'Open in editor',
  'Open in JOSM': 'Open in JSOM',
  'Download as GPX': 'Download as GPX',
  'Short': 'Short',
  'Select language and units': 'Select language and units',
  'Print': 'Print',
};
