module.exports = {
  name: 'Russian',
  'Link': 'Ссылка',
  'Open in editor': 'Открыть в редакторе',
  'Open in JOSM': 'Открыть в JSOM',
  'Download as GPX': 'Скачать как GPX',
  'Short': 'Short',
  'Select language and units': 'Выбрать язык и единицы измерения',
  'Print': 'Печать',
};
