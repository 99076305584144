module.exports = {
  name: "Deutsch",
  'Link': 'Link',
  'Open in editor': 'Im Editor öffnen',
  'Open in JOSM': 'In JSOM öffnen',
  'Download as GPX': 'Als GPX herunterladen',
  'Short': 'Verkürzt',
  'Select language and units': 'Sprache und Einheiten auswählen',
  'Print': 'Drucken',
};
